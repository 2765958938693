import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/layout"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import "react-multi-carousel/lib/styles.css"
import Carousel from "react-multi-carousel"
import axios from "axios"
import _noop from "lodash/noop"
import { Link, navigate } from "gatsby"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import getFirebase from "../../firebaseConfig"
import AuthContext from "../contexts/AuthContext"
import { STATUS, VIEWS } from "../constants/common"
import { getMetaData } from "../api/common"
import Signup from "../components/signup"
import ForgotPassword from "./forgotpassword"
import VerifyEmail from "../components/VerifyEmail"
import slide1 from "../img/testimonial-card/zocket-slider.png"
import slide2 from "../img/testimonial-card/quest1-slider.png"
import slide3 from "../img/testimonial-card/chargebee-slider.png"
import slide4 from "../img/testimonial-card/exavalu-slider.png"
import slide5 from "../img/testimonial-card/incubyte-slider.png"
import slide6 from "../img/testimonial-card/spiedge-slider.png"

const Login = props => {
  const [Email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [passwordError, setpasswordError] = useState("")
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const { loginUser, user, signOutUser } = useContext(AuthContext)
  const [metadata, setMetadata] = useState(null)
  const [fetchingMetadata, setFetchingMetadata] = useState(true)
  const [view, setView] = useState(VIEWS.LOGIN)

  useEffect(() => {
    async function fetchMetadata() {
      setFetchingMetadata(true)
      const response = await getMetaData()
      setMetadata(response)
      setFetchingMetadata(false)
    }
    fetchMetadata()
  }, [])

  useEffect(() => {
    if (user) {
      const timeoutId = setTimeout(() => {
        const lastVisitedUrl = localStorage.getItem("lastVisitedUrl")
        if (lastVisitedUrl) {
          navigate(lastVisitedUrl)
        } else {
          navigate("/") // Redirect to a default page if lastVisitedUrl is empty
        }
      }, 1000) // 2-second delay
      // Clean up the timeout if the component unmounts before the timeout finishes
      return () => clearTimeout(timeoutId)
    }
  }, [user])

  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }

  const loginSubmit = async e => {
    e.preventDefault()

    if (Email !== "" && password !== "") {
      setErrorMessage("")

      if (!validateEmail(Email)) {
        setEmailError("Please Enter Vaild Email Address")
      } else {
        setEmailError("")
      }

      if (!password) {
        setpasswordError("Please Enter Vaild Password")
      } else {
        setpasswordError("")
      }
      if (validateEmail(Email) && password.length >= 2) {
        const { status, msg, data, view } = await loginUser(Email, password)
        if (status === STATUS.FAILED) {
          setEmailError(msg)
          return
        }
        // if (view === VIEWS.VERIFY_EMAIL) {
        //   // setUserDetails(data)
        //   setView(VIEWS.VERIFY_EMAIL)
        //   return
        // }
        if (user) {
          // console.log("user.organizationId", user.organizationId)
          const lastVisitedUrl = localStorage.getItem("lastVisitedUrl")
          if (lastVisitedUrl) {
            window.location.href = lastVisitedUrl
          } else {
            window.location.href = "/my-profile"
          }
        }
      }
    } else {
      setEmailError("Please enter vaild email address")
      setpasswordError("Please enter vaild password")
    }
  }

  const getLoginView = () => setView(VIEWS.LOGIN)
  const getSignupView = () => setView(VIEWS.SIGNUP)
  const getForgotView = () => setView(VIEWS.FORGOT_PASSWORD)

  //update the image for testimonials from the images in the src\img\testimonial-card folder
  const testimonials = [
    {
      image: slide1,
      text: "GoFloaters found the perfect office for us that ticked all the boxes.",
      author: "Nadhakumar",
      role: "CEO, Zocket"
    },
    {
      image: slide2,
      text: "Finding the perfect workspace has never been easier. Highly recommend GoFloaters!",
      author: "Vishy Iyer",
      role: "CTO, Quest1"
    },
    {
      image: slide3,
      text: "GoFloaters made Chargebee's Adaptive Work Model possible.",
      author: "RU Srinivas",
      role: "SVP People Success, Chargebee"
    },
    {
      image: slide4,
      text: "GoFloaters went beyond traditional workspace offerings to meet their specific needs.",
      author: "Gautam Kanjilal",
      role: "Head of Global Delivery, Exavalu"
    },
    {
      image: slide5,
      text: "GoFloaters helped us to transform into a remote-first culture.",
      author: "Arohi Parikh",
      role: "Head of Ops, Incubyte"
    },
    {
      image: slide6,
      text: "The best platform for finding meeting spaces. Outstanding customer service!",
      author: "Krishnaprasad",
      role: "Experience Lead, Think Music"
    }
  ];

  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const TestimonialCard = ({ item }) => (
    <div style={{ 
      width: "100%",
      height: "100%",
      borderRadius: "5px",
      overflow: "hidden",
      backgroundColor: "#fff",
    }}>
      <div style={{
        width: "100%",
        height: "300px",
        position: "relative"
      }}>
        <img 
          src={item.image} 
          alt={item.author}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover"
          }}
        />
      </div>
      <div style={{
        padding: "25px",
        textAlign: "center"
      }}>
        <p style={{ 
          fontSize: "16px", 
          lineHeight: "1.6",
          color: "#333",
          marginBottom: "15px",
          fontStyle: "italic"
        }}>"{item.text}"</p>
        <h4 style={{ 
          color: "#2798B5",
          marginBottom: "5px",
          fontWeight: "600"
        }}>{item.author}</h4>
        <p style={{ 
          color: "#666",
          fontSize: "14px",
          margin: 0
        }}>{item.role}</p>
      </div>
    </div>
  );

  const loginComponent = () => {
    return (
      <div className="loginForm">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center" style={{ marginBottom: "3rem" }}>
              {/* Add GoFloaters logo */}
              <img
                data-src="https://assets.gofloaters.com/logo.png"
                className="lazyload"
                width="230"
              height="65"
              alt="GoFloaters"
            ></img>
            </div>
          </div>
          <div className="col-md-12">
            <div
            >
              <div className="text-center">
                <h3>Login</h3>
              </div>
              <form>
                <div className="form-group">
                  <label htmlFor="email">
                    Email Address *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {EmailError}
                    </span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={Email}
                    onChange={e => {
                      setEmail(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">
                    Password *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {passwordError}
                    </span>
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={password}
                    onChange={e => {
                      setPassword(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                <div className="text-center">
                  <button
                    onClick={loginSubmit}
                    style={{
                      backgroundColor: "#2798B5",
                      alignItems: "center",
                      border: "none",
                      borderRadius: 5,
                      color: "white",
                      padding: "10px 20px",
                      //button to take teh full width
                      width: "100%",
                    }}
                  >
                    Login
                  </button>
                </div>
                </div>
                <div style={{ marginTop: "10px",display: "flex", justifyContent: "space-between" }}>
                <div className="text-center">
                  <button
                    onClick={getSignupView}
                    style={{ background: "white", border: 0, color: "#2798B5" }}
                  >
                    New User? Signup
                  </button>{" "}
                </div>

                <div className="text-center">
                  <button
                    onClick={getForgotView}
                    style={{ background: "white", border: 0, color: "#2798B5" }}
                  >
                    Forgot password?
                  </button>
                </div>
                </div>
              </form>

            </div>
            {/* <div className="col-md-12">
              <div className="loginFormBadge">
                <img
                  src="https://assets.gofloaters.com/webp/trust-badge.png"
                  className="img-responsive"
                ></img>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  const signupComponent = () => {
    return <Signup getLoginView={getLoginView} metadata={metadata} />
  }

  const forgotPasswordComponent = () => {
    return <ForgotPassword getSignupView={getSignupView} />
  }

  const verifyEmailComponent = () => {
    return <VerifyEmail email={Email} />
  }

  const getCurrentView = () => {
    if (view === VIEWS.LOGIN) return loginComponent()
    if (view === VIEWS.SIGNUP) return signupComponent()
    if (view === VIEWS.FORGOT_PASSWORD) return forgotPasswordComponent()
    if (view === VIEWS.VERIFY_EMAIL) return verifyEmailComponent()
  }

  return (
    <div>
      <SEOHeader
        title={"Login | GoFloaters"}
        description={"Login | GoFloaters"}
      ></SEOHeader>

      <div>
        <div class="container-fluid newspaceDetailContainer noPadding backgroundElements">
          <div className="row alginer" style={{ height: "100vh" }}>
            <div className="col-md-5 DesktopOnly noPadding">
              {/*This section should be in the center both vertically and horizontally*/}
              <div className="login-testimonials" style={{ alignContent: "center"}}>
                <div style={{ borderRadius: "20px", overflow: "hidden", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
                  <Carousel
                    responsive={carouselResponsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    showDots={false}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    arrows={false}
                  >
                    {testimonials.map((item, index) => (
                      <TestimonialCard key={index} item={item} />
                    ))}
                  </Carousel>
                </div>
                <div className="loginFormBadge">
                  <img
                    src="https://assets.gofloaters.com/webp/trust-badge.png"
                    className="img-responsive"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              {fetchingMetadata ? <p> Loading....</p> : getCurrentView()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login

import React, { useState, useEffect, useContext } from "react"

import Layout from "../components/layout"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import "react-multi-carousel/lib/styles.css"
import axios from "axios"
import _size from "lodash/size"
import _map from "lodash/map"
import _noop from "lodash/noop"
import { Link, navigate } from "gatsby"
import {
  checkReferralCodeValidity,
  getCreateUserPayload,
  getOperationalCities,
  validateSignupDetails,
} from "../utils/common"
import { STATUS, VIEWS } from "../constants/common"
import AuthContext from "../contexts/AuthContext"
import { createUser } from "../api/common"
import { isEmailVerified } from "../utils/firebase.utils"
import Loader from "../components/Loader"
import { signOut } from "firebase/auth"
const Signup = ({ getLoginView, metadata }) => {
  const [displayName, setDisplayName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [userCity, setUserCity] = useState("")
  const [Email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [passwordError, setpasswordError] = useState("")
  const [profession, setProfession] = useState("")
  const [referral, setReferral] = useState("")
  const [reason, setReason] = useState("Shared Workspace")
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const { signupUser, setUser, user, signOutUser } = useContext(AuthContext)
  const [view, setView] = useState(VIEWS.SIGNUP)
  const [userDetails, setUserDetails] = useState(null)
  const [verifyiingEmail, setVerfyingEmail] = useState(false)
  const [signingUser, setSigningUser] = useState(false)

  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }
  const signupSubmit = async e => {
    console.log({ userCity })
    // Function to get item from localStorage if not empty
    const getFromLocalStorage = key => {
      const value = localStorage.getItem(key)
      return value !== null && value.trim() !== "" ? value : null
    }

    // Get UTM parameters from localStorage
    const utm_source = getFromLocalStorage("utm_source")
    const utm_content = getFromLocalStorage("utm_content")
    const utm_medium = getFromLocalStorage("utm_medium")
    const utm_campaign = getFromLocalStorage("utm_campaign")
    const utm_term = getFromLocalStorage("utm_term")
    const utm_timestamp = getFromLocalStorage("utm_timestamp")
    e.preventDefault()
    const validationDetails = await validateSignupDetails({
      displayName,
      phoneNumber,
      email: Email,
      companyName,
      profession,
      userCity,
    })

    // console.log({ validationDetails })

    if (validationDetails.status === STATUS.FAILED) {
      setErrorMessage(validationDetails.msg)
      return
    }

    const userDetails = {
      displayName,
      phoneNumber,
      email: Email,
      companyName,
      profession,
      userCity,
      city: userCity,
      reason,
      referral,
      campaignDetails: {
        utm_source,
        utm_content,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_timestamp,
      },
    }
    console.log("userDetails", userDetails)
    setSigningUser(true)
    try {
      if (_size(referral) > 0) {
        const { status, organizationId, msg } = await checkReferralCodeValidity(
          referral,
          Email
        )
        if (status === STATUS.SUCCESS) {
          userDetails.referral = referral
          userDetails.organizationId = organizationId
        } else if (status === STATUS.FAILED) {
          // toast.show(msg)
          setErrorMessage(msg)
          return
        }
      }

      const { status, msg, data, view } = await signupUser(Email, password)
      // console.log({ userSignupDetails })
      if (status === STATUS.FAILED) {
        alert(msg)
        return
      }

      const createUserresponse = await createUser(
        getCreateUserPayload(data?.user, userDetails, metadata)
      )
      // await signOutUser()

      setUserDetails(createUserresponse?.data ?? data)
      setUser(createUserresponse?.data ?? data)
      // alert("Account Created!")
      if (typeof window !== `undefined`) {
        // window.location.reload()
      }
      // console.log({
      //   createUserresponse,
      //   data,
      //   usreresponseData: createUserresponse?.data,
      // })
      // if (view === VIEWS.VERIFY_EMAIL) {
      //   setUserDetails(createUserresponse?.data ?? data)
      //   setView(VIEWS.VERIFY_EMAIL)
      //   return
      // }
      if (user) {
        // console.log("user.organizationId", user.organizationId)
        navigate("/")
      }

      //---End
      // if (window?.history?.length > 0) {
      //   window?.history?.back() ?? {}()
      // } else {
      //   navigate("/")
      // }
    } catch {
      alert("Something went wrong. please try again")
    } finally {
      setSigningUser(false)
    }
  }

  const onVerify = async () => {
    setVerfyingEmail(true)
    const isUserEmailVerified = await isEmailVerified()
    console.log({ isUserEmailVerified })
    if (isUserEmailVerified) {
      if (userDetails) setUser(userDetails)
      if (window?.history?.length > 0) {
        window?.history?.back() ?? _noop()
      } else {
        window.location.href = "/"
      }
    } else {
      alert("Email not verified. Please click the link sent to your email")
    }

    setVerfyingEmail(false)
  }

  const operationalCities = getOperationalCities(metadata)

  if (view === VIEWS.VERIFY_EMAIL) {
    return (
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4 d-flex flex-column align-items-center">
          <p>
            We have sent a verification link to your email{" "}
            <span className="bold">{Email}</span>. Please check.
          </p>
          {verifyiingEmail ? (
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <Loader />
            </div>
          ) : (
            <button
              onClick={onVerify}
              style={{
                backgroundColor: "#2798B5",
                alignItems: "center",
                border: "none",
                borderRadius: 4,
                color: "white",
                padding: "4px 16px",
                alignSelf: "center",
                marginBottom: 64,
              }}
            >
              Verify Email
            </button>
          )}
        </div>
      </div>
    )
  }

  if (view === VIEWS.SIGNUP)
    return (
      <div className="loginForm">
      <div className="row">
        <div className="col-md-12">
          <div>
            <div className="text-center">
              <h3>Signup</h3>
            </div>
            {ErrorMessage ? (
              <p
                style={{ color: "red", fontWeight: "bold", fontSize: "0.7rem" }}
              >
                {" "}
                * {ErrorMessage}
              </p>
            ) : null}
            <form className="row">
              <div className="col-md-6">
                {" "}
                <div className="form-group">
                  <label htmlFor="name">
                    Full Name *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}></span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    required
                    value={displayName}
                    onChange={e => {
                      setDisplayName(e.target.value)
                      setErrorMessage("")
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="company_name">
                    Company Name *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}></span>
                  </label>
                  <input
                    type="text"
                    name="company_name"
                    className="form-control"
                    required
                    value={companyName}
                    onChange={e => {
                      setCompanyName(e.target.value)
                      setErrorMessage("")
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="profession">I'm a</label>
                  <select
                    style={{ paddingRight: 4 }}
                    name="profession"
                    type="text"
                    className="form-control"
                    value={profession}
                    onChange={e => {
                      setProfession(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                  >
                    <option value="">Select</option>
                    <option value="Freelancer">Freelancer</option>
                    <option value="Independent professional">
                      Independent professional
                    </option>
                    <option value="Startup Owner/Cofounder">
                      Startup Owner/Cofounder
                    </option>
                    <option value="Startup employee">Startup employee</option>
                    <option value="Small-Medium Business Owner">
                      Small-Medium Business Owner
                    </option>
                    <option value="Large Enterprise Employee">
                      Large Enterprise Employee
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <select
                    style={{ paddingRight: 4 }}
                    name="city"
                    type="text"
                    className="form-control"
                    value={userCity}
                    onChange={e => {
                      setUserCity(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                  >
                    {/* <option value="New Delhi">New Delhi</option>
                <option value="Gurugram">Gurugram</option>
                <option value="Noida">Noida</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Pune">Pune</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Bengaluru">Bengaluru</option>
                <option value="Chennai">Chennai</option>
                <option value="Kolkata">Kolkata</option> */}
                    <option value="">Select</option>
                    {_map(operationalCities, ({ name, value }) => {
                      return <option value={value}>{name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="interested"> Interested in</label>
                  <select
                    style={{ paddingRight: 4 }}
                    name="reason"
                    type="text"
                    className="form-control"
                    value={reason}
                    onChange={e => {
                      setReason(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                  >
                    <option value="Shared Workspace">Desk</option>
                    <option value="Meeting Spaces">Meeting Spaces</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                {" "}
                <div className="form-group">
                  <label htmlFor="name">
                    Phone Number *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}></span>
                  </label>
                  <input
                    type="text"
                    name="phone_number"
                    className="form-control"
                    required
                    value={phoneNumber}
                    onChange={e => {
                      setPhoneNumber(e.target.value)
                      setErrorMessage("")
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="email">
                    Email Address *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {EmailError}
                    </span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={Email}
                    onChange={e => {
                      setEmail(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="password">
                    Password *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {passwordError}
                    </span>
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={password}
                    onChange={e => {
                      setPassword(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="referral">
                    Referral
                    <span style={{ color: "red", fontSize: "12px" }}></span>
                  </label>
                  <input
                    type="text"
                    name="referral"
                    className="form-control"
                    required
                    value={referral}
                    onChange={e => {
                      setReferral(e.target.value)
                      setErrorMessage("")
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
              <div className="form-group">
                {signingUser ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ width: "100%" }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <button
                    onClick={signupSubmit}
                    // className="btn btn-default"
                    style={{
                      backgroundColor: "#2798B5",
                      alignItems: "center",
                      border: "none",
                      borderRadius: 5,
                      color: "white",
                      padding: "10px 20px",
                      //button to take teh full width
                      width: "100%",
                    }}
                  >
                    Signup
                  </button>
                )}
</div>
                <div>
                  <button
                    onClick={getLoginView}
                    style={{
                      background: "white",
                      border: 0,
                      color: "#2798B5",
                      padding: "0",
                    }}
                  >
                    Already have an Account?
                  </button>
                </div>
                <br />
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-12">
          <br />
          <br />
        </div>
      </div>
      </div>
    )
}

export default Signup
